import googleAnalytics from '@analytics/google-analytics';
import googleTagManager from '@analytics/google-tag-manager';
import { GOOGLE_ANALYTICS_MEASUREMENT_ID, GTM_CONTAINER_ID } from '@petplate/settings';
import Analytics from 'analytics';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const analytics = Analytics({
  app: 'petplate',
  debug: true,
  plugins: [
    googleTagManager({
      containerId: GTM_CONTAINER_ID
    }),
    googleAnalytics({
      measurementIds: [GOOGLE_ANALYTICS_MEASUREMENT_ID]
    })
  ]
});

export const useTrackPageChange = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    analytics.page({
      event: 'pageview'
    });
  }, [pathname]);
};

export const AnalyticsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  useTrackPageChange();
  return <>{children}</>;
};
